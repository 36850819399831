.card-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 20px;
    gap: 15px;
    box-shadow: 1px 1px 10px rgba(49, 47, 46, 0.5);

    .card-description {
        display: flex;
        flex-direction: column;
        align-items: center;

        .card-title {
            font-size: 32px;
            font-weight: bold;
            text-align: center;
            width: 90%;
            line-height: 32px;
            padding: 10px;
        }

        .card-text {
            text-align: center;
            font-size: 18px;
            margin-bottom: 15px;
            padding: 10px;
        }

        .card-price {
            font-size: 20px;
        }
    }

    .card-image {
        height: 30vh;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        width: 100%;
        object-fit: cover;
    }
}

@include media-breakpoint-down(sm) {
    .card-container {
        .card-description {
            .card-title {
                font-size: 25px;
            }
        }
    }
}