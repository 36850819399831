.divider {
  color: white;
  border-top: 3px solid white;
  opacity: 1;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 70vw;
}

.toastify-success .Toastify__close-button svg {
  fill: white !important;
  /* Cambia il colore dell'icona in bianco */
}

$--toastify-icon-color-success: white;

.button-text {
  font-size: 25px;
  font-weight: bold;
  padding: 10px 80px;
  box-shadow: 1px 1px 10px rgba(49, 47, 46, 0.5);
}

.btn:hover {
  transform: scale(1.2);
  transition: all 0.3s ease-in-out;
  box-shadow: 1px 1px 20px rgba(49, 47, 46, 0.5);
}

body {
  background-color: $secondary
}

h1 {
  font-size: 4rem !important;
}

h2 {
  font-size: 3rem !important;
}

h3 {
  font-size: 2rem !important;
}

@include media-breakpoint-down(md) {
  h1 {
    font-size: 2.7rem !important;
  }

  h2 {
    font-size: 1.7rem !important;
  }

  h3 {
    font-size: 1.3rem !important;
  }

  .button-text {
    font-size: 20px;
    padding: 10px 30px;
  }
}

@include media-breakpoint-down(sm) {
  h1 {
    font-size: 2.5rem !important;
  }

  h2 {
    font-size: 1.5rem !important;
  }

  h3 {
    font-size: 1rem !important;
  }

  .button-text {
    font-size: 14px;
    padding: 10px 20px;
    // max-width: none;
  }
}