.collapsable-item {
    padding: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;

    &.close {
        border-bottom: 1px solid black;
    }

    .item-title {
        font-size: 35px;
    }
}

.collaps-menu {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
}

@include media-breakpoint-down(lg) {
    .collaps-menu {
        grid-template-columns: repeat(2, 1fr);
    }
}

@include media-breakpoint-down(sm) {
    .collapsable-item {
        padding: 1rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;

        &.close {
            border-bottom: 1px solid black;
        }

        .item-title {
            font-size: 28px;
        }
    }

    .collaps-menu {
        grid-template-columns: repeat(1, 1fr);
    }
}