#factory {
  .factory-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 70vw;
    margin: auto;

    .factory-text {
      font-size: 25px;
      text-align: justify;
    }

    .factory-img {
      height: 60vh;
      width: 50vw;
      object-fit: cover;
      border-radius: 20px;
      box-shadow: 1px 1px 10px rgba(49, 47, 46, 0.5);
    }

    .factory-form {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 20px;
      width: 65vw;
      margin-top: 30px;

      .factory-fields {
        display: flex;
        justify-content: center;
        width: 100%;
        gap: 20px;

        .floating-field {
          width: 100%;
        }
      }

      .textarea {
        // width: 65vw;
      }
    }
  }
}

.header-background {
  height: 20vh;
  background-color: $primary;
}

.vector {
  color: black;
  border-top: 1px solid black;
  opacity: 1;
  width: 60vw;
}

@include media-breakpoint-down(sm) {
  #factory {
    .factory-body {
      text-align: center;
      width: 80vw;

      .factory-text {
        font-size: 16px;
      }

      .factory-img {
        height: 50vh;
        width: 80vw;
      }

      .vector {
        width: 70vw;
      }

      .factory-form {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        width: 80vw;
        margin-top: 24px;

        .factory-fields {
          gap: 10px;
          flex-direction: column;

        }


        .textarea {
          width: 80vw;
        }
      }
    }
  }

}