.logo {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.container {
  max-width: 95vw;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.navbar {
  height: fit-content;
  width: 100vw;
  background-color: transparent;
  position: fixed;
  z-index: 5;
  height: 20vh;
  transition: background-color 0.5s ease-in-out, transform 0.5s ease-in-out;

  &.hidden {
    transform: translateY(-100%);
  }

  &.bg-primary {
    background-color: $primary;
  }
}

.menu-icon {
  display: none;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: 22px;
  font-weight: 400;
  color: white;
  text-decoration: none;

  &.black {
    color: black;
  }
}

.nav-elements ul a.active {
  font-weight: 700;
  // position: relative;
  text-decoration: underline;
}

// .nav-elements ul a.active::after {
//   content: "";
//   position: absolute;
//   bottom: -4px;
//   left: 0;
//   width: 100%;
//   height: 2px;
//   background-color: white;

//   .black {
//     background-color: black;
//   }
// }

//hamburger collapsed
.b-a {
  transition: all .25s;
  content: "";
  position: absolute;
  right: 0;
  height: 3px;
  width: 30px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.95);

  &.black {
    background-color: black
  }
}

.bar {
  @extend .b-a;
  right: 50px;

  &:before {
    @extend .b-a;
    top: -8px;
  }

  &:after {
    @extend .b-a;
    top: 8px;
  }
}

@include media-breakpoint-down(lg) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }

  .logo {
    width: 100px;
    height: 100px;
  }
}

@include media-breakpoint-down(md) {

  .nav-elements ul li:not(:last-child) {
    margin-right: 0;
  }

  ul {
    padding-left: 0 !important;
  }

  .menu-icon {
    display: block;
    cursor: pointer;
    z-index: 10;
    padding: 20px;
  }

  .logo {
    z-index: 10;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 0;
    background-color: $primary;
    width: 0px;
    height: 100vh;
    transition: all 0.3s ease-in;
    overflow: hidden;
    z-index: 4;
    text-align: center;
  }

  .bar.active {
    background-color: transparent;
    transition: all .25s;

    &:before {
      @extend .b-a-expanded;
      transform: rotate(45deg);
    }

    &:after {
      @extend .b-a-expanded;
      transform: rotate(-45deg);
    }
  }

  .b-a-expanded {
    transition: all .25s;
    top: -0px;
  }

  .nav-elements.active {
    width: 100vw;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }

  .navbar {
    &.hidden {
      transform: none;
    }
  }
}