 #contact {
   .contact-body {
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: column;
     width: 70vw;
     margin: auto;
     margin-bottom: 40px;
   }

   .contact-card {
     display: flex;
     width: 80vw;
     gap: 50px;
     margin-top: 30px;

     .contact-social-container {
       display: flex;
       align-items: center;
       justify-content: center;
       flex-direction: column;
       width: 60%;

       .contact-socials {
         display: flex;
         justify-content: center;
         align-items: center;
         gap: 20px;
         flex-direction: column;
         margin-top: 60px;
         width: 100%;

         .social-link {
           display: flex;
           justify-content: space-between;
           width: 100%;
           font-size: 20px;
         }
       }
     }

     .contact-map {
       max-width: 50%;
     }
   }
 }

 @include media-breakpoint-down(lg) {
   #contact {
     .contact-body {
       width: 90vw;
     }

     .contact-card {
       width: 90vw;
       gap: 0px;
       margin-top: 20px;
       flex-direction: column;

       .contact-social-container {
         margin-bottom: 30px;
         flex-direction: column;
         width: 100%;

         .contact-socials {
           margin-top: 20px;

           .social-link {
             width: 75%;
           }
         }
       }
     }
   }
 }

 @include media-breakpoint-down(sm) {
   #contact {
     .contact-card {
       .contact-social-container {
         .contact-socials {
           .social-link {
             font-size: 16px;

           }
         }
       }
     }
   }
 }