@import 'variables';

@import './styles/footer.scss';
@import './styles/header.scss';
@import "./styles/common.scss";
@import "./styles/home.scss";
@import './styles/horizontalCard.scss';
@import './styles/map.scss';
@import './styles/menu.scss';
@import './styles/about.scss';
@import './styles/factory.scss';
@import './styles/collapsable.scss';
@import './styles/card.scss';
@import './styles/contacts.scss';

/* reset */
* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  font-size: 100%;
}

// html {
//   scroll-behavior: smooth;
// }

body {
  overflow-x: hidden;
  font-family: 'FrankRuhlLibre-Regular', sans-serif;
}

ul,
ul li {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  height: auto;
}

@font-face {
  font-family: 'FrankRuhlLibre-Regular';
  src: url('./assets/font/FrankRuhlLibre-Regular.ttf');
}

@font-face {
  font-family: 'MountainsofChristmas-Regular';
  src: url('./assets/font/MountainsofChristmas-Regular.ttf');
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.9);
  // border-radius: 10px;
  background-color: $secondary;
}

body::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $primary;
  background-image: -webkit-linear-gradient(90deg,
      transparent,
      rgba(0, 0, 0, 0.4) 50%,
      transparent,
      transparent)
}