$primary: #4F0F0F;
// $secondary: #F8DFDB;
// $secondary: #FAF9F7;
$secondary: #FAEBD7;
$tertiary: #cfc36f;

$text: #000;
$text-muted: rgba(0, 0, 0, 0.4);

$grey: #eaeaea;
$white: #fff;

@import '~bootstrap/scss/bootstrap';

// $font: "Noto Sans JP", sans-serif;