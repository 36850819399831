.menu-container {
  .pinsa-day-container {
    width: 100vw;
    height: 20vh;
    background-color: $primary;
  }

  .day-card {
    width: 70vw;
    height: 60vh;
    border-radius: 20px;
    box-shadow: 2px 4px 25px rgba(49, 47, 46, 0.5);
    position: relative;
    overflow: hidden;

    .pinsa-day-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .card-text {
      position: absolute;
      background-color: $primary;
      bottom: 20px;
      right: 20px;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px 30px;
      color: white;
    }
  }



  .menu {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 70vw;
    margin: auto;

    a {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .allergens-img {
    margin: 30px 0 54px 0;
    border-radius: 20px;
    box-shadow: 2px 4px 25px rgba(49, 47, 46, 0.5);
    width: 50vw;
    height: auto;
  }
}

.down-triangle {
  .triangle {
    width: 0;
    height: 0;
    border-bottom: 30px solid transparent;
  }

  .right-triangle {
    border-left: 50vw solid $primary;
  }

  .left-triangle {
    border-right: 50vw solid $primary;
  }
}

@include media-breakpoint-down(lg) {
  .menu-container {
    .pinsa-day-container {
      width: 100vw;
      height: 20vh;
      background-color: $primary;
    }

    .day-card {
      height: 70vh;
      gap: 10%;
      flex-direction: column;

      .card-text {
        align-items: center;
        gap: 5px;
        bottom: 0;
        padding: 15px 20px;
        right: 0;
      }
    }

    .pinsa-day-img {
      width: 100%;
      height: 60%;
      border-bottom-left-radius: 0px;
      border-top-right-radius: 20px;
    }

    .allergens-img {
      width: 80vw;
    }
  }
}

@include media-breakpoint-down(sm) {
  .menu-container {
    .day-card {
      height: 55vh;
      width: 80vw;
    }

    .menu {
      width: 80vw;
    }

    .allergens-img {
      margin: 10px 0 30px 0;
    }
  }
}