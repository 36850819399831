.footer-container {
  width: 100vw;
  height: fit-content;
  margin-top: 100px;

  .triangle {
    width: 0;
    height: 0;
    border-top: 30px solid transparent;
  }

  .right-triangle {
    border-left: 50vw solid $primary;
  }

  .left-triangle {
    border-right: 50vw solid $primary;
  }
}

.footer-body {
  background-color: $primary;
  width: 100vw;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
}

.footer-sections {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;

  .time-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    width: 50%;
    gap: 10px;
    margin-top: 20px;
    height: 100%;
    border-right: 1px solid white;
  }

  .social-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    flex-direction: column;
    width: 50%;

    a {
      color: white;
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
}

@include media-breakpoint-down(md) {
  .footer-container {
    margin-top: 50px;
  }

  .footer-sections {
    flex-direction: column;

    .time-container {
      margin-top: 10px;
      width: 90%;
      border-right: 0 solid white;
      border-bottom: 1px solid white;
      padding-bottom: 20px;
    }

    .social-container {
      width: 90%;
    }
  }


}


@include media-breakpoint-down(sm) {
  .footer-container {
    margin-top: 30px;
  }

  .social-container {
    gap: 15px;
    margin-top: 10px;
  }
}