$homepage: url('../assets/images/homepage.jpg');
$homepage-vert: url('../assets/images/homepage_vert.jpg');
$home-quote: url('../assets/images/sfondo.jpg');

.background-container {
  background-image: $homepage;
  box-shadow: inset black;
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.home-title-container {
  margin-bottom: 20vh;
  margin-left: 10vw;
  z-index: 3;
}

.bg-overlay {
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0.4;
  z-index: 2;
  position: absolute;
}

.home-quote {
  background-image: $home-quote;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  font-style: italic;
  font-weight: 500;
  font-size: 25px;
  width: 100vw;
  color: white;
  position: relative;
  height: 40vh;


  .quote-text {
    position: absolute;
    z-index: 2;
    width: 100%;
    left: 0;
    padding: 50px 5vw;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .news-dark-overlay {
    width: 100vw;
    height: 40vh;
    background-color: black;
    opacity: 0.5;
    position: absolute;
    z-index: 2;
  }
}

.news-section {
  width: 100vw;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  flex-direction: column;

  // .gift-title {
  //   // font-family: 'MountainsofChristmas-Regular';
  //   font-weight: bold;
  //   letter-spacing: 5px;
  //   text-shadow: 1px 1px 2px black;
  //   color: $primary;
  // }

  .news-text-container {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 80vw;
    background-color: $primary;
    padding: 50px 5vw;
    border-radius: 20px;
    box-shadow: 1px 1px 10px rgba(49, 47, 46, 0.5);
    position: relative;

    // .gift-image {
    //   width: 150px;
    //   height: 150px;
    // }

    // .gift-closed {
    //   transform: rotate(20deg);
    //   width: 200px;
    //   height: 200px;

    //   &:hover {
    //     cursor: pointer;
    //     transform: scale(1.2) rotate(20deg);
    //     transition: all 0.2s ease-in-out;
    //   }
    // }

    // .top-right {
    //   transform: rotateY(180deg);
    //   position: absolute;
    //   top: -50px;
    //   right: -50px;
    // }

    // .top-left {
    //   position: absolute;
    //   top: -50px;
    //   left: -50px;
    // }

    .title-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .news-title {
        font-size: 30px;
        // font-weight: bold;
        // text-shadow: 1px 1px 2px white;
        // font-family: 'MountainsofChristmas-Regular';
      }

      .news-subtitle {
        font-size: 24px;
        // margin-top: 30px;
      }
    }

  }
}

.home-vector {
  color: black;
  border-top: 1px solid black;
  opacity: 1;
  margin: 20px auto;
  width: 60vw;
}

.newsletter-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin: 40px 0;

  .newsletter-subtitle {
    font-size: 24px;
  }

  .newsletter-title {
    font-size: 48px;
  }

  .form-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 50%;
    margin-top: 15px;
  }
}

.floating-field {
  width: 30vw;
}

.map-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .map-title {
    font-size: 48px;
  }
}



@include media-breakpoint-down(lg) {
  .home-title-container {
    margin-bottom: 15vh;
    margin-right: 5vw;

  }

  .home-quote {
    font-size: 22px;
    height: 50vh;

    .news-dark-overlay {
      height: 50vh;
    }
  }

  .news-section {
    // height: 40vh;

    .news-text-container {
      .news-title {
        font-size: 28px;
      }

      .news-subtitle {
        font-size: 24px;
      }
    }
  }
}

@include media-breakpoint-down(sm) {

  .background-container {
    background-image: $homepage-vert;
  }

  .home-quote {
    font-size: 18px;
    height: 60vh;

    .news-dark-overlay {
      height: 60vh;
    }
  }

  .news-section {
    padding: 30px 0;

    .news-text-container {
      .gift-image {
        width: 100px;
        height: 100px;
      }

      .gift-closed {
        width: 150px;
        height: 150px;
      }

      .top-right {
        top: -30px;
        right: -30px;
      }

      .top-left {
        top: -30px;
        left: -30px;
      }

      .title-container {
        .news-title {
          font-size: 30px;
        }

        .news-subtitle {
          font-size: 20px;
        }
      }


    }
  }

  .newsletter-section {
    margin: 20px 10vw;

    .newsletter-subtitle {
      font-size: 16px;
    }

    .newsletter-title {
      font-size: 22px;
    }

    .form-container {
      flex-direction: column;
      gap: 15px;
      width: 100%;
      align-items: center;

      .floating-field {
        width: 70vw;
      }

      .check-field {
        width: 70vw;
      }
    }
  }

  .map-section {
    .map-title {
      font-size: 22px;
    }
  }
}