#about {

  .background-container {
    background-image: url('../assets/images/attestato.jpg');
    box-shadow: inset black;
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .about-title-container {
      margin: 20vh auto;
      z-index: 3;
    }
  }

  .story-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80vw;
    margin: auto;

    .story-body {
      display: flex;
      gap: 5%;

      .about-text {
        font-size: 20px;
        margin: 20px 0;
        text-align: justify;
        width: 55%;
      }

      .about-img {
        width: 40%;
        height: 80vh;
        object-fit: cover;
        border-radius: 20px;
        box-shadow: 1px 1px 10px rgba(49, 47, 46, 0.5);
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  #about {
    .story-container {
      .story-body {
        flex-direction: column;
        align-items: center;

        .about-text {
          width: 100%;
        }

        .about-img {
          width: 50vw;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  #about {
    .story-container {
      width: 80vw;

      .story-body {
        .about-text {
          font-size: 16px;
        }

        .about-img {
          width: 70vw;
          height: 60vh;
        }
      }
    }
  }
}